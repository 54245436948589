//our CSS file
import "./recyklomatplatform.scss";

//fontawesome
import "@fortawesome/fontawesome-free/js/all.min.js";
//utils
window.istype = function(a,t){
    if(!Array.isArray(t))
        t = [t];
    try{
        return t.includes(typeof a());
    }
    catch{
        return false;
    }
};
window.isset = function(a){
    try{
        return typeof a() !== 'undefined';
    }
    catch{
        return false;
    }
};
window.getIfIsset = function(a,b){
    return window.isset(a)?a():b;
}
window.objectsEqual = function(a,b){
    var a_keys = Object.keys(a).sort();
    var b_keys = Object.keys(b).sort();
    if(a_keys.length !== b_keys.length) return false;
    if(JSON.stringify(a_keys) !== JSON.stringify(b_keys)) return false;
    for(var key of a_keys){
        if(a[key] !== b[key]) return false;
    }
    return true;
}
window.waitUntil = function(a, int=100, time=3000){
    return new Promise((resolve, reject)=>{
        if(!a()){
            const interval = setInterval(()=>{
                if(!!a()){
                    clearInterval(interval);
                    clearTimeout(timeout);
                    resolve(true);
                }
            },int);
            const timeout = setTimeout(()=>{
                clearInterval(interval);
                reject("condition was not met before timeout occured!");
            },time);
        }
        else{
            resolve(true);
        }            
    });
}
window.setCookie = function(name, value, exdays=365){
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
}
window.getCookie = function(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}
window.hasCookie = function(name){
    return window.getCookie(name) !== null;
}
window.deleteCookie = function(name){
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

window.getErrorText = function(error) {
    if(isset(()=>error.response.data.errors) && Object.keys(error.response.data.errors).length>0) {
        for(var key in error.response.data.errors) {
            if(error.response.data.errors.hasOwnProperty(key)) {
                return error.response.data.errors[key][0];
            }
        }
        
    }
    return "NIEZNANY BŁĄD";
};
//debounce
import debounce from "debounce";
window.debounce = debounce;
//scrollIntoViewIfNeeded
import scrollIntoView from "scroll-into-view-if-needed";
window.scrollIntoView = scrollIntoView;
//django translations
window.$trans = function(text, interpolate){
    if(interpolate === null || typeof interpolate === 'undefined')
        return django.gettext(text);
    else
        return django.interpolate(django.gettext(text), interpolate, true);
}
//bulma toast
import * as bulmaToast from "bulma-toast";


window.toast = function(message, type, duration, large=false){
    var msg_type;
    var icon_type;
    switch(type){
        
        case 'error':   msg_type = 'is-danger';  icon_type = 'fas fa-exclamation-circle'; break;

        case 'warning': msg_type = 'is-warning'; icon_type = 'fas fa-exclamation-triangle';   break;

        case 'success': msg_type = 'is-success';  icon_type = 'far fa-check-circle';  break;

        case 'debug':   msg_type = 'is-primary';  icon_type = 'fas fa-wrench';  break;

        case 'info': msg_type = 'is-info';   icon_type = 'fas fa-info';    break;
        default:        break;
    }

    if(typeof duration !== 'number'){
        duration = Math.max(5000,message.split(" ").length*450);
    }
    if(large==true) {
        //override message

        const largeMsg = `
            <div style="height:100vh;" class="columns is-centered is-vcentered">
                <div class="column is-half">
                    <i style="font-size:10em;" class="`+icon_type+`"></i>
                    <h1><b>`+message+`</b></h1>
                </div>
            </div>
        `
        message = largeMsg;
    } 
    bulmaToast.toast({
        message:        message,
        type:           msg_type,
        dismissible:    false,
        duration:       duration,
        position:       "bottom-center",
        animate: {
            in:         "bounceInUp",
            out:        "bounceOutDown",
            speed:      " "
        },
        pauseOnHover:   true
    });

    return new Promise((resolve)=>setTimeout(resolve, duration));
};


import init_custom_comps from "./vue-custom-components/imports.js"
//vue
import Vue from "vue";
window.Vue = Vue;
Vue.use({
    install: function(Vue, options){
        Vue.prototype.istype = window.istype;
        Vue.prototype.isset = window.isset;
        Vue.prototype.getIfIsset = window.getIfIsset;
        Vue.prototype.objectsEqual = window.objectsEqual;
        Vue.prototype.waitUntil = window.waitUntil;
        Vue.prototype.$trans = window.$trans;
        Vue.prototype.$getUrl = window.$getUrl;
        Vue.prototype.toast = window.toast;
    }
});

init_custom_comps(window.Vue);

//vue async computed
import AsyncComputed from "vue-async-computed";
Vue.use(AsyncComputed);



//axios
import axios from "axios";
window.axios = axios;
axios.interceptors.request.use(function (config) {
    if(config.method === 'post' && config.url.startsWith('/')){
        config.headers['X-CSRFToken'] = window.getCookie('csrftoken');
    }
    return config;
});

//howler
import {Howl,Howler} from 'howler';
import * as QRCode from 'easyqrcodejs'
window.QRCode = QRCode;

import mqtt from "mqtt";
import * as mqttWildcard from "mqtt";
if(typeof mqtt === 'undefined'){
    window.mqtt = mqttWildcard;
}
else{
    window.mqtt = mqtt;
}

window.getMqttUrl = function(){
    const url_suffix = '/mqtt/'
    const loc = window.location
    if(loc.hostname === 'web'){
        //special case for tests
        return `ws://mqtt:9001`
    }
    if(loc.hostname === 'localhost'){
        //special case for localhost
        return `ws://localhost:9001`
    }
    let new_url
    if (loc.protocol === "https:") {
        new_url = "wss:"
    } else {
        new_url = "ws:"
    }
    new_url += "//" + loc.host + url_suffix
    return new_url
}

import BigNumber from "bignumber.js";
window.BigNumber = BigNumber

import loaderCustom from './components/loader-component-custom.vue'

Vue.component('loader-custom-component',loaderCustom)