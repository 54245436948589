var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "loader-wrapper is-fullwidth",
      class: _vm.show ? "is-active" : "",
      staticStyle: { width: "105%" },
    },
    [
      _c(
        "progress",
        { staticClass: "progress is-large is-info", attrs: { max: "100" } },
        [_vm._v("60%")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }